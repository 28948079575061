module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pl","en"],"defaultLanguage":"pl","siteUrl":"https://example.com","localeStructure":"{{lng}}/{{ns}}","addRedirectPage":false,"redirect":true,"pathDefaultsToDefaultLanguage":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"react":{"useSuspense":false},"cache":{"enabled":true,"expirationTime":604800000,"cleanupInterval":3600000},"load":"languageOnly","keySeparator":false,"nsSeparator":false,"partialBundledLanguages":true,"ns":["common","about","gallery","contact"],"defaultNS":"common","fallbackLng":"pl","debug":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
